import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './Pagination.module.scss';

interface PaginationProps extends HTMLAttributes<HTMLDivElement> {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  loadMore?: () => void;
}

const LeftArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M11.9365 5.14312L7.05228 9.92062C6.87649 10.0901 6.79492 10.2785 6.79492 10.4982C6.79492 10.7179 6.88278 10.9188 7.05228 11.082L11.9365 15.8533C12.0809 15.9913 12.2504 16.0667 12.4576 16.0667C12.8782 16.0667 13.2046 15.7403 13.2046 15.3259C13.2046 15.125 13.1231 14.9304 12.9786 14.786L8.57785 10.5045L12.9786 6.2104C13.1231 6.06597 13.2046 5.87762 13.2046 5.67047C13.2046 5.25612 12.8782 4.92969 12.4576 4.92969C12.2567 4.92969 12.0809 5.00504 11.9365 5.14312Z"
      fill="#0063F7"
    />
  </svg>
);

const RightArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path
      d="M8.06308 15.8533L12.9473 11.0758C13.1231 10.9063 13.2047 10.7179 13.2047 10.4982C13.2047 10.2785 13.1168 10.0775 12.9473 9.91433L8.06308 5.14314C7.91865 5.00502 7.74915 4.92969 7.54201 4.92969C7.12137 4.92969 6.79492 5.25614 6.79492 5.67048C6.79492 5.87137 6.87654 6.06599 7.02093 6.21038L11.4217 10.4919L7.02093 14.786C6.87654 14.9304 6.79492 15.1188 6.79492 15.3259C6.79492 15.7403 7.12137 16.0667 7.54201 16.0667C7.74287 16.0667 7.91865 15.9913 8.06308 15.8533Z"
      fill="#0063F7"
    />
  </svg>
);

const calculateDisplayPages = (current: number, total: number) => {
  if (total < 8) {
    return [...Array(total)].map((_, i) => i + 1);
  }
  const arr: Array<number | null> = [1];

  if (current <= 2) arr.push(2);
  if (current === 2) arr.push(3);

  if (current > 2 && current <= total - 2) {
    arr.push(null, current - 1, current, current + 1, null);
  } else {
    arr.push(null);
  }

  if (current === total - 1) arr.push(total - 2);
  if (current > total - 2) arr.push(total - 1);
  arr.push(total);

  return arr;
};

const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
  loadMore,
  className,
}: PaginationProps) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const displayPages = calculateDisplayPages(currentPage, totalPages);

  const handlePageChange = (page: number) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const handleloadMore = () => {
    loadMore && loadMore();
  };

  return (
    <div className={clsx(styles.pagination, className)}>
      <ul className={styles.list}>
        <button
          className={clsx(styles.button, styles.item)}
          disabled={currentPage === 1}
          aria-label="Назад"
          onClick={() => handlePageChange(currentPage - 1)}
        >
          <LeftArrow />
        </button>
        {displayPages.map((page, i) =>
          page === null ? (
            <li key={`${page}${i}`} className={styles.item}>
              ...
            </li>
          ) : (
            <li
              key={page}
              className={clsx(styles.item, page === currentPage && styles.active)}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </li>
          )
        )}
        <button
          className={clsx(styles.button, styles.item)}
          disabled={currentPage === totalPages}
          aria-label="Вперед"
          onClick={() => handlePageChange(currentPage + 1)}
        >
          <RightArrow />
        </button>
      </ul>
      {loadMore && currentPage < totalPages && (
        <button
          className={clsx(styles.buttonLoadMore, styles.item)}
          aria-label="Показать еще"
          onClick={handleloadMore}
        >
          Показать еще
        </button>
      )}
    </div>
  );
};

export default Pagination;
