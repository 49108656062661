export const StarIcon = () => (
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.59997 13.0567L4.72663 15.39C4.55552 15.4989 4.37663 15.5456 4.18997 15.53C4.0033 15.5145 3.83997 15.4522 3.69997 15.3434C3.55997 15.2345 3.45108 15.0985 3.3733 14.9355C3.29552 14.7718 3.27997 14.5889 3.32663 14.3867L4.3533 9.97669L0.923301 7.01335C0.767746 6.87335 0.670679 6.71375 0.632101 6.53455C0.592901 6.35598 0.604412 6.18113 0.666635 6.01002C0.728857 5.83891 0.82219 5.69891 0.946635 5.59002C1.07108 5.48113 1.24219 5.41113 1.45997 5.38002L5.98663 4.98335L7.73663 0.83002C7.81441 0.643353 7.93512 0.503353 8.09877 0.41002C8.26179 0.316686 8.42886 0.27002 8.59997 0.27002C8.77108 0.27002 8.93846 0.316686 9.1021 0.41002C9.26512 0.503353 9.38552 0.643353 9.4633 0.83002L11.2133 4.98335L15.74 5.38002C15.9577 5.41113 16.1289 5.48113 16.2533 5.59002C16.3777 5.69891 16.4711 5.83891 16.5333 6.01002C16.5955 6.18113 16.6073 6.35598 16.5688 6.53455C16.5296 6.71375 16.4322 6.87335 16.2766 7.01335L12.8466 9.97669L13.8733 14.3867C13.92 14.5889 13.9044 14.7718 13.8266 14.9355C13.7489 15.0985 13.64 15.2345 13.5 15.3434C13.36 15.4522 13.1966 15.5145 13.01 15.53C12.8233 15.5456 12.6444 15.4989 12.4733 15.39L8.59997 13.0567Z"
      fill="#FFCA2C"
    />
  </svg>
);

export const ArrowIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6667 6L7.99999 10L3.33333 6"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
