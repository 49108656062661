import clsx from 'clsx';
import { HTMLAttributes } from 'react';

import styles from './ModalCloseButton.module.scss';

type Props = HTMLAttributes<HTMLButtonElement> & {};

export const ModalCloseButton = ({ className, ...rest }: Props) => {
  return (
    <button {...rest} className={clsx(styles.btn, className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M7.0498 7.05029L16.9493 16.9498"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0498 16.9497L16.9493 7.05021"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};
